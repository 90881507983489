import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import axios from 'axios';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';
import ReactMarkdown from 'react-markdown'; // Import react-markdown

type Question = {
  number: string;
  question: string;
  answer: string;
};

const Survey: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true); // Set loading to true when the questions start loading

    fetch('/Survey_Qns.csv')
      .then((response) => response.text())
      .then((data) => {
        type QuestionRow = [string, string, string];
        const parsedData = Papa.parse<QuestionRow>(data, { header: false }).data;
        const filteredData = parsedData.filter(
          (row): row is QuestionRow => row.length === 3 && !!row[0]
        );
        const questions: Question[] = filteredData.map((row) => ({
          number: row[0],
          question: row[1],
          answer: row[2],
        }));

        const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
        setQuestions(shuffledQuestions);

        if (!getLocalStorage('unansweredQuestions')) {
          setLocalStorage('unansweredQuestions', JSON.stringify(shuffledQuestions.map(q => q.number)));
        }

        const unansweredQuestions = JSON.parse(getLocalStorage('unansweredQuestions') || '[]');
        if (unansweredQuestions.length > 0) {
          const lastUnansweredQuestion = unansweredQuestions[0];
          const index = shuffledQuestions.findIndex((q) => q.number === lastUnansweredQuestion);
          setCurrentQuestionIndex(index);
        } else {
          if (getLocalStorage('completed')) {
            navigate('/thanks');
          }
        }

        setLoading(false); // Set loading to false after questions are loaded
      });
  }, [navigate]);

  const handleAnswerClick = async (relevance: string): Promise<void> => {
    setLoading(true);

    const unansweredQuestions = JSON.parse(getLocalStorage('unansweredQuestions') || '[]');
    const currentQuestion = questions[currentQuestionIndex];

    try {
      if (relevance !== 'unsure') {
        await axios.post('/api/add-response', {
          question_no: currentQuestion.number,
          relevance: relevance === 'relevant',
        });
      }

      const updatedUnansweredQuestions = unansweredQuestions.filter((q: string) => q !== currentQuestion.number);
      setLocalStorage('unansweredQuestions', JSON.stringify(updatedUnansweredQuestions));

      if (updatedUnansweredQuestions.length === 0) {
        setLocalStorage('completed', 'true');
        navigate('/thanks');
      } else {
        const nextUnansweredQuestion = updatedUnansweredQuestions[0];
        const nextIndex = questions.findIndex((q) => q.number === nextUnansweredQuestion);
        setCurrentQuestionIndex(nextIndex);
      }
    } catch (error) {
      console.error('Error sending response:', error);
    } finally {
      setLoading(false);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  if (!questions.length) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-orange-500"></div>
          <span className="text-lg text-gray-700">Loading Survey...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between items-center px-6 py-4 bg-orange-100 min-h-dvh max-h-dvh">
      {/* Top Half: Question */}
      <div className="w-full flex flex-col sm:flex-row justify-between items-start bg-white shadow-md rounded-lg p-4 sm:p-6 mb-6 overflow-hidden">
        {/* Question Section */}
        <div className="w-full sm:w-1/4 p-4 sm:p-6 border-b sm:border-r sm:border-b-0 border-orange-200">
          <h2 className="text-2xl font-bold text-orange-700">Question</h2>
          <p className="mt-6 text-xl text-gray-800 text-left">{currentQuestion.question}</p>
        </div>

        {/* Divider for Mobile */}
        <div className="sm:hidden border-t border-gray-300 my-4 w-full"></div>

        {/* Answer Section with Scroll */}
        <div className="w-full sm:w-3/4 p-4 sm:p-6 max-h-[70vh] sm:max-h-[60vh] overflow-y-auto">
          <h2 className="text-2xl font-bold text-orange-700">Answer</h2>
          <div className="mt-6 text-lg text-gray-700 text-left">
            <ReactMarkdown className="markdown">{currentQuestion.answer}</ReactMarkdown>
          </div>
        </div>
      </div>

      {/* Bottom Half: Action Buttons */}
      <div className="w-full flex flex-row sm:flex-row justify-between gap-4 mt-auto">

        {/* Not Relevant Button */}
        <button
          className="w-full sm:w-1/3 sm:mx-10 bg-red-500 text-white font-bold py-6 rounded-lg shadow-lg hover:bg-red-600 transition flex flex-col items-center"
          onClick={() => handleAnswerClick('not relevant')}
          disabled={loading}
        >
          <span className="text-5xl mb-2">👎</span>
          <span className="text-lg sm:text-xl">Not Relevant</span>
        </button>

        {/* Unsure Button */}
        <button
          className="w-full sm:w-1/3 sm:mx-10 bg-yellow-500 text-white font-bold py-6 rounded-lg shadow-lg hover:bg-yellow-600 transition flex flex-col items-center"
          onClick={() => handleAnswerClick('unsure')}
          disabled={loading}
        >
          <span className="text-5xl mb-2">🤷‍♂️</span>
          <span className="text-lg sm:text-xl">Unsure</span>
        </button>

        {/* Relevant Button */}
        <button
          className="w-full sm:w-1/3 sm:mx-10 bg-green-500 text-white font-bold py-6 rounded-lg shadow-lg hover:bg-green-600 transition flex flex-col items-center"
          onClick={() => handleAnswerClick('relevant')}
          disabled={loading}
        >
          <span className="text-5xl mb-2">👍</span>
          <span className="text-lg sm:text-xl">Relevant</span>
        </button>
      </div>


      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-white z-20">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-orange-500"></div>
            <span className="text-lg text-gray-700">Submitting...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Survey;
