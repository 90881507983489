import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Survey from './pages/Survey';
import Thanks from './pages/Thanks';
import ProtectedRoute from './components/ProtectedRoute';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* Protected route for Survey */}
      <Route 
        path="/survey" 
        element={
          <ProtectedRoute requiredKeys={['started']} redirectTo="/" />
        }
      >
        <Route index element={<Survey />} />
      </Route>

      {/* Protected route for Thanks */}
      <Route 
        path="/thanks" 
        element={
          <ProtectedRoute requiredKeys={['started', 'completed']} redirectTo="/survey" />
        }
      >
        <Route index element={<Thanks />} />
      </Route>

      {/* Catch-all for undefined routes */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
