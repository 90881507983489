import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);  // Track image load state

  useEffect(() => {
    // Use getLocalStorage to check if 'started' flag exists
    const started = getLocalStorage('started');
    if (started === 'true') {
      setHasStarted(true);
    }
  }, []);

  const handleStart = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    try {
      // Make the API call to increment the population count
      await axios.post('/api/add-pop-count');
      
      // Set local storage and update state
      setLocalStorage('started', 'true');
      setHasStarted(true);

      // Navigate to the survey page
      navigate('/survey');
    } catch (error) {
      console.error('Error adding population count:', error);
      // You can add more error handling if necessary
    }
  };

  const handleContinue = (event: React.MouseEvent<HTMLButtonElement>): void => {
    navigate('/survey');
  };

  return (
    <div className="relative flex items-center justify-center bg-orange-50 px-4 sm:px-8 min-h-dvh">
      
      {/* Loading Screen */}
      {!isImageLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-orange-500"></div>
            <span className="text-lg text-gray-700">Loading...</span>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="text-center max-w-xl p-8 bg-white rounded-lg shadow-md border border-orange-200 w-full sm:w-auto">
        
        {/* Logo Section */}
        <img 
          src="assets/aether.png" 
          alt="Aether Logo" 
          className="mx-auto mb-6 w-1/4 sm:w-1/5 md:w-1/6 lg:w-1/8 h-auto max-w-full"
          onLoad={() => setIsImageLoaded(true)}  // Set image load state to true once loaded
        />

        <h1 className="text-4xl font-bold text-orange-600 mb-4">
          Hi! <span role="img" aria-label="waving emoji">👋</span>
        </h1>
        <p className="text-lg text-gray-800 mb-6">
          Thank you for agreeing to do this survey on the 
          <strong> relevance of AI-generated responses to human queries</strong>.
        </p>

        <div className="text-left text-gray-700 mb-8">
          <h2 className="text-xl font-semibold text-orange-500 mb-2">Instructions:</h2>
          <ol className="list-decimal list-inside pl-4 space-y-2">
            <li>In each question, you will receive a <b className="text-black">human query</b> and a <b className="text-black">AI-generated response</b>. Your job is to determine whether the response is <b className="text-green-500">relevant</b> or <b className="text-red-800">not relevant.</b> If you are unsure, you can choose <b className="text-yellow-600">unsure</b>.</li> 
            <li>You may stop doing the survey at any time. Your current responses will automatically be saved (on your device), and you can continue doing the survey at any time. To exit, simply close the tab.</li>
          </ol>
        </div>

        <button 
          onClick={hasStarted ? handleContinue : handleStart} 
          className="px-6 py-3 bg-orange-500 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-orange-600 transition-all"
        >
          {hasStarted ? 'Continue Survey' : 'Start Survey'}
        </button>
      </div>
    </div>
  );
};

export default Home;
