import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLocalStorage } from '../utils/localStorage';

interface ProtectedRouteProps {
  requiredKeys: string[];
  redirectTo: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredKeys, redirectTo }) => {
  const hasAllRequiredKeys = requiredKeys.every((key) => Boolean(getLocalStorage(key)));

  if (!hasAllRequiredKeys) {
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
