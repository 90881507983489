import React from 'react';

const Thanks: React.FC = () => {

  return (
    <div className="min-h-screen bg-orange-50 flex items-center justify-center p-4 min-h-dvh">
      <div className="bg-white p-12 rounded-lg shadow-lg max-w-lg w-full">
        <h1 className="text-4xl font-semibold text-center text-orange-600 mb-6">
          Thank You for Completing the Survey!
        </h1>
        <p className="text-center text-black">
          Your response is invaluable, and we appreciate your time.
        </p>
      </div>
    </div>
  );
};

export default Thanks;
